.App {
    text-align: center;
}

/**
 * Loading Animation Snippet
 */

.loading {
    color: transparent;
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%;
    animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
